import type { CurrentRefinementsProvided } from 'react-instantsearch-core';

import type {
  DescriptionPage_CourseType as CourseType,
  DescriptionPage_DifficultyLevel as DifficultyLevel,
  DescriptionPage_ProductVariant as ProductVariant,
} from '__generated__/graphql-types';
import type Maybe from 'graphql/tsutils/Maybe';
import _, { capitalize } from 'lodash';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import localStorageEx from 'bundles/common/utils/localStorageEx';
import {
  ALL_PRODUCTS_INDEX_TYPE,
  RECENTLY_VIEWED_LOCAL_STORAGE_NAME,
  RECENT_ITEM_NUMBER_OF_DAYS_TO_LAST,
  RECENT_SEARCHES_LOCAL_STORAGE_NAME,
  SHORT_FORM_CONTENT_ENTITY_TYPES,
  UCI_APM_IMAGE_URL,
  UCI_APM_OBJECT_ID,
} from 'bundles/search-common/constants';
import { SEARCH_FILTER_OPTION_ORDER_OVERRIDES_REIMAGINE_VARIANT_C } from 'bundles/search-common/constants/filter';
import type {
  ProductCardCourse,
  ProductCardSpecialization,
  SearchProductHit,
} from 'bundles/search-common/providers/searchTypes';
import type { Hits, RecentlyViewed, Viewed } from 'bundles/search-common/types/autocomplete';
import { getAllTabIndexName, getIndexTypeToNameMap } from 'bundles/search-common/utils/utils';
import inServerContext from 'bundles/ssr/util/inServerContext';

export { getAllTabIndexName } from 'bundles/search-common/utils/utils';

export const isShortFormContent = (entityType?: string) => SHORT_FORM_CONTENT_ENTITY_TYPES.includes(entityType || '');

export const getNumberOfSearchResults = (currentTab: string, allSearchResults: Record<string, { nbHits: number }>) => {
  const indexTypeToNameMap = getIndexTypeToNameMap();
  const indexName = currentTab === ALL_PRODUCTS_INDEX_TYPE ? getAllTabIndexName() : indexTypeToNameMap[currentTab];
  return allSearchResults?.[indexName]?.nbHits;
};

// Creates video item id from parentLessonObjectUrl since the videoItemId currently isn't being passed in algolia
// for parent lessons. This will be able to be removed after it is added to the algolia index after short form
// content launch
export const getLessonsVideoObjectUrl = (parentCourseId?: string, parentLessonObjectUrl?: string) => {
  return tupleToStringKey([
    'sfcvideo',
    parentCourseId || '',
    parentLessonObjectUrl?.split('/')[3]?.split('?')[0] || '',
  ]);
};

// TODO(htran) remove after UCI APM Contentful migration is done in GNG-1259
export const getSearchCardImageUrl = (hit?: {
  objectID?: Maybe<string>;
  imageUrl?: Maybe<string>;
  partnerLogos?: Maybe<Array<string>>;
}): string => {
  if (!hit) {
    return '';
  } else if (hit.objectID && hit.objectID === UCI_APM_OBJECT_ID) {
    return UCI_APM_IMAGE_URL;
  } else {
    const productImage = hit?.imageUrl || '';

    // If image is a gif it is detrimental to performance, serve the partner logo instead.
    if (/\.gif/.test(productImage)) {
      return hit?.partnerLogos ? hit.partnerLogos[0] : productImage;
    } else {
      return productImage;
    }
  }
};

export const getUserLanguageRoot = () => {
  if (!inServerContext) {
    let userLanguage = navigator ? navigator.language : undefined;
    userLanguage = userLanguage?.split('-')[0];
    return userLanguage;
  }
  return undefined;
};

export function getItemsListFromLocalStorageByKeyAndTrimOutdated(
  keyName: typeof RECENT_SEARCHES_LOCAL_STORAGE_NAME
): string[];

export function getItemsListFromLocalStorageByKeyAndTrimOutdated(
  keyName: typeof RECENTLY_VIEWED_LOCAL_STORAGE_NAME
): Viewed[];

export function getItemsListFromLocalStorageByKeyAndTrimOutdated(keyName: string): string[] | Viewed[] {
  if (!inServerContext && localStorageEx.isAvailable()) {
    const localStorageItems: RecentlyViewed[] = localStorageEx.getItem(keyName, JSON.parse, []);
    const now = new Date();
    const filteredLocalStorageItems = localStorageItems.filter((item: RecentlyViewed) => {
      const dateSaved = new Date(item.dateSaved);
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      return (
        dateSaved && (now.getTime() - dateSaved.getTime()) / millisecondsPerDay < RECENT_ITEM_NUMBER_OF_DAYS_TO_LAST
      );
    });

    localStorageEx.setItem(keyName, filteredLocalStorageItems, JSON.stringify);
    return filteredLocalStorageItems.map((item: RecentlyViewed) => item.suggestion);
  } else {
    return [];
  }
}

// productType from XDP comes from multiple different sources which is why there are multiple cases
export const getProductType = (productType: CourseType | ProductVariant | string | null): string => {
  if (productType) {
    switch (productType) {
      case 'STANDARD_COURSE':
      case 'COURSE':
        return 'Course';
      case 'PROJECT':
        return 'Project';
      case 'RHYME_PROJECT':
      case 'GUIDED_PROJECT':
        return 'Guided Project';
      case 'SPECIALIZATION':
      case 'STANDARD_SPECIALIZATION':
        return 'Specialization';
      case 'PROFESSIONAL_CERTIFICATE':
      case 'GOOGLE_CERTIFICATE':
        return 'Professional Certificate';
      case 'MASTERTRACK':
      case 'MASTER_TRACK':
        return 'Mastertrack';
      case 'PostgraduateDiploma':
      case 'POSTGRADUATE_DIPLOMA':
        return 'Postgraduate Diploma';
      case 'GRADUATE_CERTIFICATE':
        return 'Graduate Certificate';
      case 'UNIVERSITY_CERTIFICATE':
        return 'University Certificate';
      case 'BACHELORS_DEGREE':
      case 'BachelorsDegree':
      case 'MASTERS_DEGREE':
      case 'DEGREE':
        return 'Degree';
      case 'LESSON':
        return 'Lesson';
      case 'VIDEO':
        return 'Video';
      case 'SKILL_CAREERROLE':
        return 'Career Role';
      default:
        return '';
    }
  }
  return '';
};
export const saveRecentlyViewed = ({
  id,
  name,
  slug,
  path,
  imageIconWithSize,
  partnerName,
  partnerLogo,
  difficulty,
  productType,
}: {
  id?: string;
  name: string;
  slug?: string;
  path: string;
  imageIconWithSize: {
    imageUrl: string;
    size: number;
  };
  partnerName?: string;
  partnerLogo?: string;
  difficulty?: DifficultyLevel | null;
  productType?: CourseType | ProductVariant | string | null;
}) => {
  if (!inServerContext && localStorageEx.isAvailable()) {
    const recentlyViewed = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENTLY_VIEWED_LOCAL_STORAGE_NAME);
    const elementIndex = recentlyViewed.findIndex((storedItem: Viewed) => storedItem.name === name);
    if (elementIndex !== -1) {
      recentlyViewed.splice(elementIndex, 1);
    }
    recentlyViewed.unshift({
      id,
      name,
      slug,
      path,
      image: imageIconWithSize,
      partnerName: partnerName || null,
      partnerLogo,
      difficulty: difficulty ? capitalize(difficulty) : '',
      productType: productType ? getProductType(productType) : '',
    });
    const now = new Date();
    const recentlyViewedWithTimestamp = recentlyViewed.map((suggestion: Viewed) => ({ suggestion, dateSaved: now }));
    localStorageEx.setItem(RECENTLY_VIEWED_LOCAL_STORAGE_NAME, recentlyViewedWithTimestamp, JSON.stringify);
  }
};
export const saveRecentlySearched = (searchTerm: string) => {
  if (!inServerContext && localStorageEx.isAvailable() && searchTerm.trim() !== '') {
    const recentlySearched = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENT_SEARCHES_LOCAL_STORAGE_NAME);
    const elementIndex = recentlySearched.findIndex((storedItem: string) => storedItem === searchTerm);
    if (elementIndex !== -1) {
      recentlySearched.splice(elementIndex, 1);
    }
    recentlySearched.unshift(searchTerm);
    const now = new Date();
    const recentlySearchedWithTimestamp = recentlySearched.map((suggestion: string) => ({
      suggestion,
      dateSaved: now,
    }));
    localStorageEx.setItem(RECENT_SEARCHES_LOCAL_STORAGE_NAME, recentlySearchedWithTimestamp, JSON.stringify);
  }
};

export const getDataToTrackFromHit = (hit: Hits) => {
  const { indexPosition, hitPosition, indexName, objectID } = hit;
  return { indexPosition, hitPosition, indexName, objectID };
};

export const getNameFromDomainOrSubdomainId = (id: string) => {
  const name = id
    .split('-')
    .map((word: string) => word[0].toUpperCase() + word.substring(1))
    .join(' ');
  return name;
};

export type ItemType = {
  count: number;
  isRefined: boolean;
  label: string;
  value: string | Array<string>;
  supportText?: string;
};

type ItemBase = {
  count: number;
  isRefined: boolean;
  label: string;
};

export const getFilterItemsOrder = <Item extends ItemBase>(items: Item[], attribute: string) => {
  // use specific ordering for Level and Duration only
  const optionItemOrderOverride = SEARCH_FILTER_OPTION_ORDER_OVERRIDES_REIMAGINE_VARIANT_C[attribute];
  let sortedItems: Item[] = items;

  if (!optionItemOrderOverride) {
    sortedItems = _.orderBy(items, ['isRefined', 'count', 'label'], ['desc', 'desc', 'asc']);
  } else {
    sortedItems.sort((item1, item2) => {
      if (item1.isRefined && !item2.isRefined) {
        return -1;
      } else if (!item1.isRefined && item2.isRefined) {
        return 1;
      } else if (optionItemOrderOverride.indexOf(item1.label) < optionItemOrderOverride.indexOf(item2.label)) {
        return -1;
      } else {
        return 1;
      }
    });
  }
  return sortedItems;
};

/* @deprecated */
export const getAppliedFilters = (items: CurrentRefinementsProvided['items'][0][]) => {
  const filtersApplied: Record<string, string[] | string> = {};

  if (!items || !items.length) {
    return filtersApplied;
  }

  items.forEach((filterCategory) => {
    filtersApplied[filterCategory.attribute] = filterCategory.currentRefinement;
  });

  return filtersApplied;
};

export const getPartners = (id: string, cobrandingEnabled: boolean, partners?: string[]) => {
  if (!partners || !partners.length) return '';

  if (partners.length > 1 && cobrandingEnabled) {
    return `${partners[0]}, ${partners[1]}`;
  }

  return partners[0];
};
export const getIsPathwayContent = (hit: SearchProductHit): boolean => {
  if (hit?.productCard?.productTypeAttributes) {
    const productTypeAttributes = hit.productCard.productTypeAttributes;
    if ('isPathwayContent' in productTypeAttributes) {
      return (
        (productTypeAttributes as Partial<ProductCardCourse> | Partial<ProductCardSpecialization>).isPathwayContent ??
        false
      );
    }
  }
  return false;
};

export const getAvgRating = (hit: SearchProductHit): number | undefined => {
  if (hit?.productCard?.productTypeAttributes) {
    const productTypeAttributes = hit.productCard.productTypeAttributes;
    if ('rating' in productTypeAttributes) {
      return productTypeAttributes.rating ?? undefined;
    }
  }
};
export const getNumOfRatings = (hit: SearchProductHit): number | undefined => {
  if (hit?.productCard?.productTypeAttributes) {
    const productTypeAttributes = hit.productCard.productTypeAttributes;
    if ('reviewCount' in productTypeAttributes) {
      return productTypeAttributes.reviewCount ?? undefined;
    }
  }
};
