import * as React from 'react';
import type { Settings as SliderSettings } from 'react-slick';
import Slider from 'react-slick';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Retracked from 'js/app/retracked';
import { isRightToLeft } from 'js/lib/language';

import { VisuallyHidden } from '@coursera/cds-core';
import { Button, breakPoint, color } from '@coursera/coursera-ui';
import { SvgChevronLeft, SvgChevronRight } from '@coursera/coursera-ui/svg';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import type { CarouselSettings } from 'bundles/design-system/components/types';
import { updateSlickAccessibilityAttributes } from 'bundles/design-system/utils/react-slick-utils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/design-system';

import 'css!bundles/design-system/components/__styles__/Carousel';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })(Button);
const ReDisabled = /\bslick-disabled\b/;
const SLIDE_CHANGE_ANNOUNCEMENT_DELAY = 500; // ms

type ArrowButtonProps = {
  className?: string;
  isArrowRight?: boolean;
  isArrowNext?: boolean;
  label?: string;
  iconColor?: string;
  hoverColor?: string;
  flip?: boolean;
};

export const ArrowButton = ({
  isArrowNext,
  isArrowRight,
  label,
  iconColor,
  hoverColor,
  flip,
  ...rest
}: ArrowButtonProps) => {
  const disabled = rest.className ? ReDisabled.test(rest.className) : false;
  // A note about left/right w.r.t RTL: Stylus rules are automatically "flipped," so we don't need to manually flip them
  // like we do the icon. However, react-slick flips the button semantics, so we do flip next/prev. Tragically, the
  // tracking names use the visual direction rather than the logical direction, so it is extra confusing.
  const classes = classnames('slider-button', {
    'slider-left': flip ? isArrowNext : !isArrowNext,
    'slider-right': flip ? !isArrowNext : isArrowNext,
    'slider-disabled': disabled,
  });

  const ariaLabel = label ?? (isArrowNext ? _t('go to next slide') : _t('go to previous slide'));
  const trackingName = isArrowNext ? 'carousel_slider_right_arrow' : 'carousel_slider_left_arrow';

  return (
    <TrackedButton
      rootClassName={classes}
      type="icon"
      trackingName={trackingName}
      htmlAttributes={{
        'aria-label': ariaLabel,
        'aria-disabled': disabled,
        tabIndex: disabled ? -1 : undefined,
      }}
      {...rest}
    >
      {isArrowRight ? (
        <SvgChevronRight
          htmlAttributes={{ 'aria-label': ariaLabel }}
          title={ariaLabel}
          size={48}
          color={iconColor ?? color.black}
          hoverColor={hoverColor ?? color.darkPrimary}
        />
      ) : (
        <SvgChevronLeft
          htmlAttributes={{ 'aria-label': ariaLabel }}
          title={ariaLabel}
          size={48}
          color={iconColor ?? color.black}
          hoverColor={hoverColor ?? color.darkPrimary}
        />
      )}
    </TrackedButton>
  );
};

const customPaging = (i: number) => (
  <button type="button" aria-label={_t('slide #{slideNumber}', { slideNumber: i + 1 })} />
);

const appendDots = (dots: React.ReactNode) => {
  const listItems = React.Children.map(dots, (li) => {
    if (!React.isValidElement<React.LiHTMLAttributes<HTMLLIElement>>(li)) {
      return li;
    }
    const button = li.props.children;
    if (!React.isValidElement<React.ButtonHTMLAttributes<HTMLButtonElement>>(button)) {
      return li;
    }
    const isActive = li.props.className?.includes('slick-active');
    const buttonWithAria = React.cloneElement(button, {
      'aria-current': isActive,
    });
    return React.cloneElement(li, { children: buttonWithAria });
  });
  return <ul aria-label={_t('slides')}>{listItems}</ul>;
};

const DEFAULT_SETTINGS: SliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  dots: false,
  initialSlide: 0,
  lazyLoad: 'ondemand',
  responsive: [
    {
      breakpoint: breakPoint.xl,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: breakPoint.lg,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: breakPoint.md,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: breakPoint.sm,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const A11yPagingSettings: SliderSettings = {
  customPaging,
  appendDots,
};

export type Props = {
  isExpanded?: boolean;
  settings?: CarouselSettings;
  enableVisibilityTracking?: boolean;
  enableRtl?: boolean;
  arrowProps?: ArrowButtonProps;
  programId?: string;
  collectionId?: string;
  title?: string | null;
  setSlickRef?: (slickRef: Slider | null) => void;
  setCurrentSlideNumber?: React.Dispatch<React.SetStateAction<number>>;
};

type State = {
  leftSwipeCount: number;
  rightSwipeCount: number;
  leftCount: number;
  rightCount: number;
  currentViewedItem: number;
  currentSlideNumber: number;
};

class Carousel extends React.Component<Props, State> {
  static contextTypes = {
    _eventData: PropTypes.object,
  };

  static defaultProps = {
    enableVisibilityTracking: true,
    arrowProps: {},
  };

  get shouldUseRTL() {
    // We use tricks to make react-slick somewhat functional in RTL, but we only want to do that if the document is
    // served in RTL and the parent component is prepared for them.
    return Boolean(this.props.enableRtl) && isRightToLeft(_t.getLocale());
  }

  get shouldUseFlip() {
    return !this.props.enableRtl && isRightToLeft(_t.getLocale());
  }

  componentDidMount() {
    this.timeoutId = window.setTimeout(() => {
      let event;
      if (typeof Event === 'function') {
        event = new Event('resize');
      } else {
        // IE 11 compatibility: https://stackoverflow.com/questions/27176983/dispatchevent-not-working-in-ie11
        event = window.document.createEvent('Event');
        event.initEvent('resize', true, true);
      }
      window.dispatchEvent(event);

      if (this.shouldUseRTL) {
        // react-slick is super broken in RTL, seemingly never tested for it with advanced layouts. When it initializes
        // in RTL, crucial state members are not set correctly, which breaks the carousel on first interaction. I've
        // found that clicking the "first" dot gets it to fix itself, so I'm doing that programmatically here. The
        // maintainer has not been attentive recently and many RTL issues/PRs have been dismissed or ignored.
        //
        // Long term, we should consider forking react-slick to fix the issues (and PR them back, hopefully they get
        // merged), or we should switch to a better maintained dependency.
        //
        // `innerSlider` is internal state that we are punching through to for the purpose of implementing the above
        // trick.
        //
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const innerSlider = this.slickRef?.innerSlider;
        if (innerSlider) {
          const spec = { ...innerSlider.props, ...innerSlider.state };
          innerSlider.changeSlide(
            /* options: */ {
              message: 'dots',
              index: Math.max(0, Math.ceil((spec.slideCount - spec.slidesToShow) / spec.slidesToScroll)),
              slidesToScroll: spec.slidesToScroll,
              currentSlide: spec.currentSlide,
            },
            /* dontAnimate: */ true
          );
        }
      }
    }, 0);

    this.updateSlickAccessibilityAttributes();
  }

  componentDidUpdate() {
    this.updateSlickAccessibilityAttributes();
  }

  updateSlickAccessibilityAttributes() {
    // Reach into slick to get a handle on the track DOM node.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const listElement: HTMLElement | undefined | null = this.slickRef?.innerSlider?.list;
    if (listElement) updateSlickAccessibilityAttributes(listElement, this.props.title);
  }

  componentWillUnmount = () => {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  };

  slickRef: Slider | null = null;
  slideChangeNotificationRef: HTMLElement | null = null;

  timeoutId = 0;

  state = {
    leftSwipeCount: 0,
    rightSwipeCount: 0,
    rightCount: 0,
    leftCount: 0,
    currentViewedItem: 0,
    currentSlideNumber: 0,
  };

  onCarouselChange = (idx: number) => {
    const { currentViewedItem } = this.state;
    const { _eventData } = this.context;
    if (_eventData) {
      if (idx > currentViewedItem) {
        this.setState(
          ({ rightCount }) => ({ rightCount: rightCount + 1, currentViewedItem: idx }),
          () => {
            Retracked.trackComponent(
              _eventData,
              {
                rightCount: this.state.rightCount,
                leftCount: this.state.leftCount,
                currentViewedItem: this.state.currentViewedItem,
              },
              'carousel',
              'carousel_right'
            );
          }
        );
      } else if (idx < currentViewedItem) {
        this.setState(
          ({ leftCount }) => ({ leftCount: leftCount + 1, currentViewedItem: idx }),
          () => {
            Retracked.trackComponent(
              _eventData,
              {
                rightCount: this.state.rightCount,
                leftCount: this.state.leftCount,
                currentViewedItem: this.state.currentViewedItem,
              },
              'carousel',
              'carousel_left'
            );
          }
        );
      }
    }
  };

  onCarouselSwipe = (swipeDirection: string) => {
    const { _eventData } = this.context;
    if (_eventData) {
      if (swipeDirection === 'left') {
        this.setState(
          ({ leftSwipeCount }) => ({ leftSwipeCount: leftSwipeCount + 1 }),
          () => {
            Retracked.trackComponent(
              _eventData,
              { leftSwipeCount: this.state.leftSwipeCount, rightSwipeCount: this.state.rightSwipeCount },
              'carousel',
              'carousel_swipe_left'
            );
          }
        );
      } else if (swipeDirection === 'right') {
        this.setState(
          ({ rightSwipeCount }) => ({ rightSwipeCount: rightSwipeCount + 1 }),
          () => {
            Retracked.trackComponent(
              _eventData,
              { leftSwipeCount: this.state.leftSwipeCount, rightSwipeCount: this.state.rightSwipeCount },
              'carousel',
              'carousel_swipe_right'
            );
          }
        );
      }
    }
  };

  beforeCarouselChange = (oldIndex: number, newIndex: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const innerSlider = this.slickRef?.innerSlider;
    const { setCurrentSlideNumber } = this.props;
    if (this.slideChangeNotificationRef) {
      this.slideChangeNotificationRef.style.display = 'block';
    }
    if (innerSlider) {
      //A11Y: delay setting CurrentSlideNumber by 500ms to allow screen reader detect change
      setTimeout(() => {
        const spec = { ...innerSlider.props, ...innerSlider.state };
        const currentPage = Math.round(newIndex / spec.slidesToScroll + 1);
        this.setState({ currentSlideNumber: currentPage });

        if (setCurrentSlideNumber) {
          setCurrentSlideNumber(currentPage);
        }
        setTimeout(() => {
          if (this.slideChangeNotificationRef) {
            this.slideChangeNotificationRef.style.display = 'none';
          }
        }, SLIDE_CHANGE_ANNOUNCEMENT_DELAY);
      }, SLIDE_CHANGE_ANNOUNCEMENT_DELAY);
    }
  };

  setSlickRef = (slickRef: Slider | null) => {
    this.slickRef = slickRef;
    this.props.setSlickRef?.(slickRef);
  };
  setSlideChangeNotificationRef = (slideChangeNotificationRef: null) => {
    this.slideChangeNotificationRef = slideChangeNotificationRef;
  };

  renderSlider = () => {
    const { children, settings, arrowProps } = this.props;
    const rtl = this.shouldUseRTL;
    // For things where the react-slick RTL mode just outright doesn't work (e.g. PDP Screenshots carousel), still fix
    // the arrow being wrong.
    const flip = this.shouldUseFlip;
    // Apply default A11Y tweaks to the pagination, unless the caller is doing their own thing (in which case, they will
    // handle A11Y).
    const a11yPagingSettings =
      settings?.customPaging == null && settings?.appendDots == null ? A11yPagingSettings : null;

    const nextLabel = settings?.nextArrowLabel || _t('go to next slide');
    const prevLabel = settings?.prevArrowLabel || _t('go to previous slide');
    const sliderSettings = Object.assign(
      {
        rtl,
        nextArrow: (
          <ArrowButton
            isArrowNext={!rtl}
            isArrowRight={true}
            flip={flip}
            label={rtl ? prevLabel : nextLabel}
            {...arrowProps}
          />
        ),
        prevArrow: (
          <ArrowButton
            isArrowNext={rtl}
            isArrowRight={false}
            flip={flip}
            label={rtl ? nextLabel : prevLabel}
            {...arrowProps}
          />
        ),
      },
      a11yPagingSettings,
      DEFAULT_SETTINGS,
      settings
    );

    return (
      <Slider
        afterChange={this.onCarouselChange}
        beforeChange={this.beforeCarouselChange}
        onSwipe={this.onCarouselSwipe}
        {...sliderSettings}
        ref={this.setSlickRef}
      >
        {children}
      </Slider>
    );
  };

  render() {
    const { children, settings, enableVisibilityTracking } = this.props;
    const { currentSlideNumber } = this.state;
    const childrenArray = React.Children.toArray(children);

    const visibilityTrackingProps = enableVisibilityTracking
      ? { withVisibilityTracking: enableVisibilityTracking, requireFullyVisible: enableVisibilityTracking }
      : undefined;

    return (
      <TrackedDiv
        className={classnames('rc-Carousel', {
          stepped: !settings?.isFluid,
        })}
        trackingName="carousel"
        {...visibilityTrackingProps}
      >
        <VisuallyHidden component="span" className="rc-A11yScreenReaderOnly">
          <div
            ref={this.setSlideChangeNotificationRef}
            style={{ display: 'none' }}
            aria-atomic="true"
            aria-live="assertive"
          >
            {currentSlideNumber > 0 &&
              _t('Slide content has changed, slide #{currentSlideNumber}.', { currentSlideNumber })}
            {childrenArray[currentSlideNumber - 1]}
          </div>
        </VisuallyHidden>
        {this.renderSlider()}
      </TrackedDiv>
    );
  }
}

export default Carousel;
