import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('formGroup', [
  'hasCardsInTheGroup',
  'groupWrapper',
  'groupWrapperTwoColumns',
  'groupWrapperThreeColumns',
]);

const getFormGroupCss = css`
  .${classes.groupWrapper} {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--cds-spacing-50) var(--cds-spacing-400);
    margin-top: var(--cds-spacing-50);

    &.${classes.groupWrapperTwoColumns} {
      grid-template-columns: 1fr 1fr;

      ${breakpoints.down('sm')} {
        grid-template-columns: 1fr;
      }
    }

    &.${classes.groupWrapperThreeColumns} {
      grid-template-columns: 1fr 1fr 1fr;

      ${breakpoints.down('md')} {
        grid-template-columns: 1fr 1fr;
      }

      ${breakpoints.down('sm')} {
        grid-template-columns: 1fr;
      }
    }

    &.${classes.hasCardsInTheGroup} {
      margin-top: var(--cds-spacing-100);
      gap: var(--cds-spacing-150) var(--cds-spacing-100);
    }
  }
`;

export default getFormGroupCss;
