/* 
  HOC to add Google SSO functionality

  Utilizes Google's OAuth 2.0 authorization flow (https://developers.google.com/identity/protocols/oauth2)
*/
import * as React from 'react';
import { Component } from 'react';

import URI from 'jsuri';
import _ from 'lodash';

import config from 'js/app/config';

import SSOError from 'bundles/authentication/shared/components/authentication-modal/SocialSSO/SSOError';
import { OAUTH } from 'bundles/third-party-auth/constants';
import Instrumentation from 'bundles/userModal/lib/instrumentation';

import _t from 'i18n!nls/authentication';

type OAuthProps = {
  onSuccess: (isRegistration: boolean) => void;
  onError?: (error: SSOError) => void;
  redirectTo?: string;
  forwardedRef?: React.Ref<HTMLElement>;
};

type HandlerFunction = (params: URI) => void;

type State = {
  connecting: boolean;
};

// @see https://regex101.com/r/MV8ynU/1
const COURSERA_MATCHER = /.*[.]coursera[.](org|cc)$/;
const MUTED_ERRORS = ['notAuthorized', 'unknownStatus'];

function open(url: string): Window | null {
  const height = 600;
  const width = 450;

  const left = (screen.width - width) / 2;
  const top = (screen.height - height) / 3.5;

  return window.open(
    url,
    _t('Redirecting'),
    'width=' + width + ', height=' + height + ', top=' + top + ', left=' + left
  );
}

const withOAuth = <Props extends {}>(
  WrappedComponent: React.ComponentType<Props>,
  serviceName: 'google'
): React.ComponentClass<Props & OAuthProps, State> => {
  const name = WrappedComponent.displayName ?? WrappedComponent.name;

  class OAuth extends Component<Props & OAuthProps, State> {
    static displayName = `OAuth(${name})`;

    state = { connecting: false };

    handleError: HandlerFunction = (params) => {
      const { onError = () => undefined } = this.props;

      this.setState({ connecting: false });

      const code = params.getQueryParamValue('errorType');
      const service = params.getQueryParamValue('service');
      let existingAccountType;

      if (!MUTED_ERRORS.includes(code)) {
        Instrumentation.thirdPartyError(service, code);
        if (code === 'accountLinkedToSocialAccount') {
          existingAccountType = params.getQueryParamValue('existingAccountType');
        }

        onError(new SSOError({ code, existingAccountType }, service));
      }
    };

    handleSuccess: HandlerFunction = (params) => {
      this.setState({ connecting: false });

      const { onSuccess = () => undefined } = this.props;

      const response = {};
      const isRegistration = params.getQueryParamValue('isRegistration') === 'true';
      const service = params.getQueryParamValue('service');

      _.extend(response, { isRegistration });
      Instrumentation.thirdPartyAuth(service, response);

      onSuccess(isRegistration);
    };

    connect = () => {
      const { redirectTo } = this.props;

      this.setState({ connecting: true });

      const redirectURL = new URL(OAUTH.ENDPOINT_TEMPLATE.replace('<serviceName>', serviceName));
      const queryParams = {
        redirectURL: `${config.url.base}ssoCallback`,
        clientState: redirectTo,
      };

      Object.entries(queryParams).forEach(([key, value]) => redirectURL.searchParams.append(key, String(value)));

      const view = open(redirectURL.toString());

      if (!view) {
        // Redirect current window if popup cannot be opened
        window.location.href = redirectURL.toString();

        return;
      }

      const interval = setInterval(() => {
        if (view.closed) {
          this.setState({ connecting: false });
          clearInterval(interval);
        }
      }, 500);

      window.addEventListener('message', (event) => {
        if (COURSERA_MATCHER.test(event.origin) && typeof event.data === 'string') {
          const params = new URI(event.data);
          const success = params.getQueryParamValue('responseType') === 'success';

          if (success) {
            this.handleSuccess(params);
          } else {
            this.handleError(params);
          }
        }
      });
    };

    render() {
      const { props, state } = this;
      const { connecting } = state;

      return <WrappedComponent {...props} disabled={connecting} onClick={this.connect} ref={props.forwardedRef} />;
    }
  }

  return OAuth;
};

export default withOAuth;
