import { useSubscriptionsByUser } from 'bundles/coursera-plus/utils/withSubscriptionsByUser';
import {
  isNewCPlusEntryPointsVariant,
  isNewCPlusEntryPointsVariantOptout,
} from 'bundles/page-header/utils/experimentUtils';

export type NewCPlusEntryPointsEligibilityStatus = {
  shouldShowCourseraPlusBanner: boolean;
  isEligibleUser: boolean;
};

const useNewCPlusEntryPointsEligibilityCheck = (): NewCPlusEntryPointsEligibilityStatus => {
  const shouldSkipQueries = isNewCPlusEntryPointsVariantOptout();

  const { subscriptions } = useSubscriptionsByUser(shouldSkipQueries);

  const hasActiveCourseraPlusOrLiteSubscription = subscriptions?.find(
    (subscription) =>
      subscription.isActive && (subscription.isCourseraPlusSubscription || subscription.isCourseraLiteSubscription)
  );

  const isEligibleUser = !hasActiveCourseraPlusOrLiteSubscription;

  return {
    shouldShowCourseraPlusBanner: isEligibleUser && isNewCPlusEntryPointsVariant(),
    isEligibleUser,
  };
};

export default useNewCPlusEntryPointsEligibilityCheck;
