type Options = {
  body?: string;
  timeout?: number;
  url: string;
};

const TIMEOUT = 30 * 1000; // 30s in ms

/**
 *
 * @param {Options} options
 * @param {string} options.body - (optional) Body of the script tag to inject <script>[body]</script>
 * @param {number} options.timeout - (optional) Milliseconds before promise is rejected due to failure loading the script
 * @param {string} options.url - URL of the script to inject
 * @return {Promise} - Return a promise that resolves when script is loaded, rejects when there is some connection errors or timeout is reached
 */
function loadScript(options: Options): Promise<void> {
  const { body, timeout = TIMEOUT, url } = options;

  if (document.querySelector(`script[src='${url}']`)) {
    return Promise.resolve();
  }

  return new Promise((ok, ko) => {
    const script = document.createElement('script');

    script.async = true;
    script.type = 'text/javascript';

    if (body) {
      script.innerHTML = body;
    }

    const timer = setTimeout(() => {
      const error = new Error(`${timeout}ms timeout elapsed loading third-party script.`);
      Object.assign(error, { url, body });

      ko(error);
    }, timeout);

    script.onload = () => {
      clearTimeout(timer);

      ok();
    };
    script.onerror = (error) => {
      clearTimeout(timer);

      ko(error);
    };

    script.src = url;

    const [sibling] = document.getElementsByTagName('script');
    const { parentNode } = sibling ?? {};

    if (parentNode) {
      parentNode.insertBefore(script, sibling);
    } else {
      document.body.appendChild(script);
    }
  });
}

export default { loadScript };
