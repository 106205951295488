import type { SerializedStyles } from '@emotion/react';

import * as React from 'react';

import { SvgGoogle } from '@coursera/coursera-ui/svg';

import SocialButton from 'bundles/authentication/components/SSOButton';
import type SSOError from 'bundles/authentication/shared/components/authentication-modal/SocialSSO/SSOError';
import useAuthenticationModalTracking from 'bundles/authentication/shared/hooks/useAuthenticationModalTracking';
import withOAuthSSO from 'bundles/authentication/with/OAuthSSO';
import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { SSOTypes } from 'bundles/third-party-auth/lib';

import _t from 'i18n!nls/authentication';

type Props = {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  btnLabel?: string;
  customStyles?: SerializedStyles;
  iconColor?: string;
};

export type TopLevelGoogleProps = {
  onError: (error: SSOError) => void;
  onSuccess: (isRegistration: boolean) => void;
  service?: string;
};

const TrackedSocialButton = withSingleTracked({ type: 'BUTTON' })(SocialButton);

export const Google = React.forwardRef(
  ({ className, disabled = false, onClick, btnLabel, customStyles, iconColor }: Props, ref: React.Ref<HTMLElement>) => {
    const handleOnClick = useAuthenticationModalTracking({ onClick, buttonName: 'signup_with_google' });
    return (
      <TrackedSocialButton
        className={className}
        disabled={disabled}
        icon={SvgGoogle}
        iconColor={iconColor}
        customStyles={customStyles}
        onClick={handleOnClick}
        trackingName="continue_with_google_auth_btn"
        _refAltAsTrueRef={ref}
      >
        {btnLabel ?? _t('Continue with Google')}
      </TrackedSocialButton>
    );
  }
);
Google.displayName = 'Google';

const TopLevelGoogle = (props: TopLevelGoogleProps) => {
  const Component = withOAuthSSO(Google, SSOTypes.GOOGLE);

  return <Component {...props} />;
};

export default TopLevelGoogle;
