import React from 'react';

import { useForkRef } from '@material-ui/core/utils';

import type { CollectionChildren } from '@react-types/shared';

import { useLocalizedStringFormatter } from '@coursera/cds-common';

import type { SelectProps } from '@core/forms/Select';
import { Popover } from '@core/Popover';
import { useControlled } from '@core/utils';
import VisuallyHidden from '@core/VisuallyHidden';

import constants from './constants';
import Controller from './Controller';
import type { Props as ControllerProps } from './Controller';
import i18nMessages from './i18n';
import type { Props as ListBoxProps } from './ListBox';
import ListBox from './ListBox';
import Toolbar from './Toolbar';

export type CloseReason =
  | 'backdropClick'
  | 'escapeKeyDown'
  | 'confirmedSelection';
export type Value = Iterable<string | number>;

export type Props<OptionType extends object> = {
  /**
   * Placeholder text. Use to provide minimum context.
   * Used as a label in drawer on XS breakpoint.
   */
  placeholder: string;

  /**
   * The input value. Use when the component should be controlled.
   */
  value?: Value;

  /**
   * Default input value. Used to pre select options for uncontrolled component.
   */
  defaultValue?: Value;

  /**
   * Defines a visual style of the controller.
   * @default outlined
   */
  variant?: ControllerProps['variant'];

  /**
   * If set to `true`, the Search component will be rendered inside the popover.
   * @default false
   */
  searchable?: boolean;

  /**
   * If set to `true`, the popover will render loading state.
   */
  loading?: ListBoxProps<OptionType>['loading'];

  /**
   * If set, the controller (trigger button) width will be fixed.
   */
  width?: number;

  /**
   * Use this prop to set all available options that also can change dynamically.
   * Prefer this prop in combination with children render prop if the list of options is huge.
   */
  items?: Iterable<OptionType>;

  /**
   * Must be a `MultiSelect.Option` or `MultiSelect.Group` components when static.
   * Has to be a render prop when `items` prop is set
   *
   * @example
   * Static children:
   *
   * ```tsx
   *  <MultiSelect.Option key="1">1</MultiSelect.Option>
   *  <MultiSelect.Option key="2">2</MultiSelect.Option>
   *  <MultiSelect.Option key="3">3</MultiSelect.Option>
   * ```
   *
   * Dynamic:
   * ```tsx
   *  {(option) => (<MultiSelect.Option key={option.value}>{option.label}</MultiSelect.Option>)}
   * ```
   */
  children: CollectionChildren<OptionType>;

  /**
   * Callback, fired when a value of the input has changed.
   */
  onChange?: (values: Value) => void;

  /**
   * Callback, fired when dropdown is closed.
   */
  onClose?: (event: React.SyntheticEvent, reason: CloseReason) => void;

  /**
   * Callback, fired after dropdown opens.
   */
  onOpen?: (event: React.SyntheticEvent) => void;

  /**
   * Callback, fired when search input value has changed.
   * When specified the search results will not be filtered automatically.
   */
  onSearchChange?: ListBoxProps<OptionType>['onSearchChange'];

  /**
   * Props passed to the search input. Allow to customize labels.
   */
  searchProps?: {
    placeholder?: string;
    'aria-label'?: string;
  };

  /**
   * Defines a string value that labels controller element.
   * If set will be announced instead of placeholder.
   */
  'aria-label'?: string;

  /**
   * Identifies element or elements that provides label for controller.
   * If set will be announce instead of placeholder.
   */
  'aria-labelledby'?: ControllerProps['aria-labelledby'];
  /**
   * When true, hides clear all button
   * @default false
   */
  hideClear?: boolean;
} & Pick<
  SelectProps,
  'className' | 'id' | 'inputProps' | 'name' | 'open' | 'autoFocus'
>;

const MultiSelectComponent = <T extends object>(
  props: Props<T>,
  ref: React.Ref<HTMLButtonElement>
): React.ReactElement => {
  const {
    autoFocus,
    placeholder,
    children,
    name,
    variant,
    value: valueProp,
    defaultValue = new Set(),
    width,
    inputProps,
    items,
    onClose,
    onChange,
    onOpen,
    searchable,
    loading,
    onSearchChange,
    searchProps,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    hideClear = false,
  } = props;

  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const [minWidth, setMinWidth] = React.useState<number | undefined>(undefined);
  const [selectedOptions, setSelectedOptions] = React.useState<Value>(
    new Set()
  );

  const [value, setValue] = useControlled({
    default: defaultValue,
    controlled: valueProp,
    name: 'MultiSelect',
    state: 'value',
  });

  const [openState, setOpen] = useControlled<boolean>({
    controlled: props.open,
    default: false,
    name: 'MultiSelect',
    state: 'open',
  });

  const [
    controllerEl,
    setControllerEl,
  ] = React.useState<HTMLButtonElement | null>(null);

  const isOpen = controllerEl !== null && openState;

  const listRef = React.useRef<HTMLUListElement>(null);

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const openDropdown = (event: React.SyntheticEvent) => {
    setOpen(true);
    setMinWidth(controllerEl?.clientWidth);

    // Preset selected options if value is set
    if (new Set(value).size) {
      setSelectedOptions(value);
    } else {
      setSelectedOptions(new Set());
    }

    onOpen?.(event);
  };

  const handleTriggerKeyUp = (event: React.KeyboardEvent) => {
    const { key } = event;
    const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' ']; // includes Spacebar (the last element of the array)

    if (
      event.currentTarget instanceof HTMLButtonElement &&
      openKeys.includes(key)
    ) {
      event.preventDefault();
      openDropdown(event);
    }
  };

  const handleClose = (
    event: React.SyntheticEvent,
    reason: CloseReason = 'confirmedSelection'
  ) => {
    setOpen(false);
    onClose?.(event, reason);
  };

  const handleSelection = (value: Value | 'all') => {
    if (value !== 'all') {
      setSelectedOptions(value);
    }
  };

  const handleApplyChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (__DEV__ && valueProp && !onChange) {
      console.warn(
        'CDS: Component is used as controlled. Consider adding `onChange` callback or use `defaultValue` instead of `value`.'
      );
    }

    if (!valueProp) {
      setValue(selectedOptions);
    }

    if (onChange) {
      onChange(selectedOptions);
    }

    handleClose(event);
  };

  const handleClearSelection = () => {
    setSelectedOptions(new Set());

    if (onChange) {
      onChange(new Set());
    } else {
      setValue(new Set());
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <Controller
        ref={useForkRef(ref, (element) => setControllerEl(element))}
        active={isOpen}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
        autoFocus={autoFocus}
        placeholder={placeholder}
        selectedItemsNumber={new Set(value).size}
        variant={variant}
        width={width}
        onClick={openDropdown}
        onKeyDown={handleTriggerKeyUp}
      />
      <VisuallyHidden>
        <input
          name={name}
          onChange={handleChange}
          {...inputProps}
          aria-hidden
          tabIndex={-1}
          value={Array.from(value).join(',')}
        />
      </VisuallyHidden>
      <Popover
        anchorElement={controllerEl}
        drawerProps={{
          height: searchable ? '100%' : undefined,
          autoFocus: false,
        }}
        dropdownProps={{
          // Focus is handled by the ListBox
          autoFocus: false,
          marginTop: 'var(--cds-spacing-150)',

          maxHeight: `calc(100vh - ${constants.DROPDOWN_OFFSET} - ${constants.CONTROLLER_HEIGHT} - var(--cds-spacing-150))`,
          PaperProps: {
            style: {
              // keep min width in sync with controller
              minWidth,
            },
          },
        }}
        initialFocusRef={searchable ? searchInputRef : undefined}
        open={isOpen}
        onClose={handleClose}
      >
        {({ isDrawer }) => {
          return (
            <>
              <ListBox
                ref={listRef}
                aria-label={stringFormatter.format('listLabel')}
                autoFocus={searchable ? false : 'first'}
                headerProps={{
                  label: placeholder,
                  searchable,
                  isDrawer,
                  searchInputRef,
                }}
                items={items}
                loading={loading}
                searchProps={searchProps}
                selectedKeys={selectedOptions}
                selectionMode="multiple"
                onSearchChange={onSearchChange}
                onSelectionChange={handleSelection}
              >
                {children}
              </ListBox>
              <Toolbar
                hideClear={hideClear}
                onApply={handleApplyChange}
                onClear={handleClearSelection}
              />
            </>
          );
        }}
      </Popover>
    </React.Fragment>
  );
};

// forwardRef doesn't support generic parameters, so cast the result to the correct type
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
const MultiSelect = React.forwardRef(MultiSelectComponent) as <
  T extends object
>(
  props: Props<T> & { ref?: React.Ref<HTMLButtonElement> }
) => ReturnType<typeof MultiSelectComponent>;

export default MultiSelect;
