/* eslint-disable no-restricted-syntax */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import config from 'js/app/config';
import Imgix from 'js/components/Imgix';
import { FormattedMessage } from 'js/lib/coursera.react-intl';

import _t from 'i18n!nls/search-common';

const styles = {
  noteWrapper: css`
    padding: 20px 0 40px;
  `,
  resultsIcon: css`
    margin-right: 15px;
  `,
  altHeading: css`
    padding: 0 0 25px 0;
    font-weight: bold;
  `,
};

type Props = {
  query?: string;
  recommendationHitsLength?: number;
};

const NoSearchResultsMessage = ({ query, recommendationHitsLength }: Props) => {
  return (
    <React.Fragment>
      <div css={styles.noteWrapper}>
        <Imgix
          src={`${config.url.resource_assets}search/assets/degree-zero-state.png`}
          alt={_t('Default Results Icon')}
          maxWidth={46}
          maxHeight={46}
          imgParams={{ fit: 'fill', auto: 'format, compress' }}
          css={styles.resultsIcon}
        />
        <FormattedMessage
          message={_t("We couldn't find any exact matches related to {query}")}
          query={<strong>{query}</strong>}
        />
      </div>
      {recommendationHitsLength && (
        <div css={styles.altHeading}>{_t('You might be interested in our following recommendations')}</div>
      )}
    </React.Fragment>
  );
};

export default NoSearchResultsMessage;
