/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { memo } from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Skeleton, Typography, Typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import { SearchResultsHeaderText } from 'bundles/search-common/components/SearchResultsHeaderText';

import _t from 'i18n!nls/search-common';

type Props = {
  searchQuery?: string;
  numberOfHits?: number;
  shouldHide?: boolean;
  activeFilters?: string[];
  isLoading?: boolean;
  activeFilterCount?: number;
  hitCountRounder?: (count: number) => number;
  lessenHeaderFocus?: boolean;
  hideHits?: boolean;
};

const styles = {
  header: (theme: Theme) => css`
    min-height: 36px;
    margin-bottom: 24px;

    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      min-height: 32px;
    }
  `,
  placeholderWrapper: css`
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;

    .results-text-placeholder {
      position: relative;
      display: block;
      top: -2px;
      margin-bottom: 10px !important;
    }

    .filter-placeholder {
      padding-bottom: 4px;
      margin-top: 6px;
      display: flex;
      justify-content: left;

      .chip-placeholder {
        margin-right: 16px;
      }
    }
  `,
};

export const SearchResultsHeader: React.FC<Props> = (props: Props) => {
  const {
    searchQuery,
    numberOfHits,
    shouldHide,
    activeFilters,
    isLoading,
    activeFilterCount,
    hitCountRounder,
    lessenHeaderFocus = false,
    hideHits = false,
  } = props;

  if (shouldHide) {
    return null;
  }

  if (isLoading) {
    return (
      <div css={styles.placeholderWrapper}>
        <Skeleton className="results-text-placeholder" width="17em" height="46px" />

        {Boolean(activeFilterCount) && (
          <div className="filter-placeholder">
            {[
              Array.from({ length: Math.min(activeFilterCount ?? 0, 5) }).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Skeleton className="chip-placeholder" width="8em" height="46px" key={i} />
              )),
            ]}
          </div>
        )}
      </div>
    );
  }

  if (isLoading === false && numberOfHits === undefined) {
    const content = (
      <div data-e2e="NumberOfResultsSection">
        <FormattedMessage
          message={searchQuery ? _t('No results found for "{query}"') : _t('No results found for your search')}
          query={searchQuery}
        />
      </div>
    );

    return (
      <div className="rc-SearchResultsHeader">
        {lessenHeaderFocus ? (
          <Typography2 variant="subtitleLarge" component="h2" role="heading" css={styles.header}>
            {content}
          </Typography2>
        ) : (
          <Typography variant="h1semibold" role="heading" css={styles.header}>
            {content}
          </Typography>
        )}
      </div>
    );
  }

  const content = (
    <div data-e2e="NumberOfResultsSection">
      <SearchResultsHeaderText
        searchQuery={searchQuery}
        numberOfHits={numberOfHits}
        activeFilters={activeFilters}
        hitCountRounder={hitCountRounder}
        hideHits={hideHits}
      />
    </div>
  );

  return (
    <div className="rc-SearchResultsHeader">
      {lessenHeaderFocus ? (
        <Typography2 variant="subtitleLarge" component="h2" role="heading" css={styles.header}>
          {content}
        </Typography2>
      ) : (
        <Typography variant="h1semibold" component="h2" role="heading" css={styles.header}>
          {content}
        </Typography>
      )}
    </div>
  );
};

export default memo(SearchResultsHeader);
