import growthAcquisitionExperiments from 'bundles/epic/clients/GrowthAcquisition';

// The funtion will return true if the user is not impressed into the mobile epic.
const isReduceRecaptchaMobileOptedOut = () =>
  growthAcquisitionExperiments.preview('reduceRecaptchaMobile') === 'optOut';

// The web and mobile epics are mutually uxclusive with each other due to the UserAgent targeting.
// We should check if the epic is opted out one of them (no variant and impresion).
// If yes, generate impression for the other epic.
export const generateImpressionForReduceRecaptcha = () =>
  isReduceRecaptchaMobileOptedOut()
    ? growthAcquisitionExperiments.get('reduceRecaptchaWeb')
    : growthAcquisitionExperiments.get('reduceRecaptchaMobile');

export const previewReduceRecaptcha = () =>
  isReduceRecaptchaMobileOptedOut()
    ? growthAcquisitionExperiments.preview('reduceRecaptchaWeb')
    : growthAcquisitionExperiments.preview('reduceRecaptchaMobile');

export const isReduceRecaptchaControlVariant = () => previewReduceRecaptcha() === 'control';

export const isReduceRecaptchaUsabilityVariant = () => previewReduceRecaptcha() === 'usability';

export const isReduceRecaptchaBypassVariant = () => previewReduceRecaptcha() === 'bypass';
