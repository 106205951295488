/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2, breakpoints, typography2 } from '@coursera/cds-core';

import type { IndustryRelevanceCareer } from 'bundles/premium-hub/data/industryRelevanceModuleContent';

type SalaryChartProps = {
  salaries: IndustryRelevanceCareer[];
  citation?: string;
};

const SALARY_VALUE_WIDTH = 100;
const styles = {
  salaryContainer: css`
    position: relative;
    margin-bottom: var(--cds-spacing-100);

    ${breakpoints.up('sm')} {
      padding-right: ${SALARY_VALUE_WIDTH}px;
    }

    &:nth-child(2n) {
      > div {
        background-color: var(--cds-color-interactive-primary-hover);
      }
    }

    &:nth-child(3n) {
      > div {
        background-color: var(--cds-color-callouts-tertiary);
      }
    }
  `,

  careerName: css`
    white-space: nowrap;

    ${breakpoints.down('xs')} {
      font-weight: bold;
      margin-right: var(--cds-spacing-100);
    }
  `,

  salaryBar: css`
    position: relative;
    padding: var(--cds-spacing-100);
    background-color: var(--cds-color-interactive-primary);
    border-radius: 4px;
    min-width: min-content;
  `,

  salaryValue: css`
    ${breakpoints.up('sm')} {
      padding: var(--cds-spacing-100);
      position: absolute;
      top: 2px;
      right: -${SALARY_VALUE_WIDTH}px;
      width: ${SALARY_VALUE_WIDTH}px;
      color: var(--cds-color-neutral-primary-weak);
      ${typography2.subtitleMedium}
    }
  `,
  citation: css`
    margin-top: var(--cds-spacing-300);

    ${breakpoints.down('sm')} {
      margin-bottom: var(--cds-spacing-300);
    }
  `,
};

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const SalaryChart = ({ salaries, citation }: SalaryChartProps) => {
  const highestSalary = salaries.reduce((highest, { averageSalary }) => {
    return averageSalary > highest ? averageSalary : highest;
  }, 0);

  return (
    <div>
      {salaries.map(({ averageSalary, name, id }) => {
        const width = (averageSalary / highestSalary) * 100;
        return (
          <div key={id} css={styles.salaryContainer}>
            <div
              className="salaryBar"
              css={[
                styles.salaryBar,
                css`
                  width: ${width}%;
                `,
              ]}
            >
              <Typography2 css={styles.careerName} variant="bodySecondary" color="invertBody" component="span">
                {name}
              </Typography2>
              <Typography2 css={styles.salaryValue} variant="bodySecondary" color="invertBody" component="span">
                {USDollar.format(averageSalary)}
                {citation && '*'}
              </Typography2>
            </div>
          </div>
        );
      })}
      {citation && (
        <Typography2 component="p" variant="bodySecondary" color="supportText" css={styles.citation}>
          {citation}
        </Typography2>
      )}
    </div>
  );
};

export default SalaryChart;
