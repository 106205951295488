import * as React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { branch, compose, mapProps } from 'recompose';

import user from 'js/lib/user';

import AdminPermission from 'bundles/admin/utils/AdminPermission';
import Naptime from 'bundles/naptimejs';
import AdminUserPermissionsV1 from 'bundles/naptimejs/resources/adminUserPermissionsV1';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import AuthenticatedAccountInfo from 'bundles/page-header/components/AuthenticatedAccountInfoV2';
import CareerAcademyLink from 'bundles/page-header/components/CareerAcademyLink';
import EnterpriseLink from 'bundles/page-header/components/EnterpriseLink';
import type { Course } from 'bundles/page-header/components/PageHeader';
import StudentLink from 'bundles/page-header/components/StudentLink';
import UnauthenticatedAccountInfo from 'bundles/page-header/components/UnauthenticatedAccountInfo';
import DegreeDropdown from 'bundles/page-header/components/desktop/DegreeDropdown';
import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';
import { applicationStoreIn } from 'bundles/page/lib/migration';
import type { Program } from 'bundles/page/types/Program';
import savedCartUtils from 'bundles/payments/lib/savedCartUtils';

import _t from 'i18n!nls/page';

import 'css!bundles/page-header/components/__styles__/HeaderRightNavV2';

const EXPLORE_CATALOG_LINK =
  '/for-university-and-college-students?utm_campaign=zoom-video-lecture&utm_content=browse-catalog-top-nav&utm_medium=coursera&utm_source=zoom';

type InputProps = {
  isEnterprise?: boolean;
  hideEnterprise?: boolean;
  showShoppingCart?: boolean;
  hideNotificationCenter?: boolean;
  hideHelp?: boolean;
  showAdminLinks?: boolean;
  programs?: Array<EnterpriseProgramsV1>;
  degrees?: Array<Record<string, string>>;
  onClickHelp?: () => void;
  q2ShowDesktopAltSignupLabel?: () => boolean;
  experimentalStyles?: boolean;
  thirdPartyOrganizationId?: string;
  showExploreCatalog?: boolean;
  hideLogIn?: boolean;
  course?: Course;
  showLanguagesDropdown?: boolean;
  itemId?: string;
  hideDropdownOptions?: boolean;
  currentProgram?: Program;
  isStandaloneAuth?: boolean;
};

type Props = InputProps & {
  hasActiveShoppingCart: boolean;
  adminPermission: AdminPermission;
};

type RightNavButtonsType = {
  href?: string;
  name: string;
  label: string;
  mobileOnly?: boolean;
  wrapperClassName?: string;
  onClick?: () => void;
  target?: string;
  openInNewWindow?: boolean;
};

class HeaderRightNav extends React.Component<Props> {
  static contextTypes = {
    router: PropTypes.object,
    getStore: PropTypes.func,
  };

  static defaultProps = {
    hideEnterprise: false,
    hideHelp: true,
    hasActiveShoppingCart: false,
    adminPermission: new AdminPermission({}),
  };

  getUserAgent() {
    const { context } = this;
    return applicationStoreIn(this) && context.getStore('ApplicationStore').getUserAgent();
  }

  getRequestCountryCode() {
    const { context } = this;
    return applicationStoreIn(this) && context.getStore('ApplicationStore').getRequestCountryCode();
  }

  isAndroidOrIOS() {
    const userAgent = this.getUserAgent();
    return userAgent && (userAgent.isIOS || userAgent.isAndroid);
  }

  getButtonTextMediaQuery = () => {
    const { hasActiveShoppingCart } = this.props;

    return {
      maxWidth: hasActiveShoppingCart ? 1120 : 1080,
      minWidth: 925,
    };
  };

  render() {
    const {
      programs,
      degrees,
      showShoppingCart,
      hasActiveShoppingCart,
      hideNotificationCenter,
      onClickHelp,
      hideHelp,
      hideEnterprise,
      q2ShowDesktopAltSignupLabel,
      isEnterprise,
      showAdminLinks,
      experimentalStyles,
      thirdPartyOrganizationId,
      adminPermission,
      showExploreCatalog,
      course,
      hideLogIn,
      showLanguagesDropdown,
      itemId,
      hideDropdownOptions,
      currentProgram,
      isStandaloneAuth,
    } = this.props;

    const childClassName = ['bt3-nav', 'bt3-navbar-nav'];
    const childListClassName = ['c-navbar-list', ...childClassName].join(' ');
    const childItemClassName = ['c-navbar-item', ...childClassName].join(' ');

    const shouldShowOptionalElements = !hideEnterprise && !showAdminLinks && !showExploreCatalog;
    const showAuthenticatedAccountInfo = user.isAuthenticatedUser() && programs && degrees;

    return (
      <PageHeaderContext.Consumer>
        {({ isSimplifiedPageHeader, subNavigationLinks }) => {
          // We only show these links for unified header if there are no subNavLinks and no currentProgram meaning it is not an enterprise page.
          const showUnifiedLinks =
            isSimplifiedPageHeader && shouldShowOptionalElements && !subNavigationLinks && !currentProgram;

          return (
            <div
              id="c-ph-right-nav"
              className={classNames('c-ph-right-nav bt3-navbar-right collapse', {
                'is-cart-active': hasActiveShoppingCart,
              })}
            >
              {shouldShowOptionalElements && !isSimplifiedPageHeader && (
                <span className={childItemClassName} role="none" style={{ padding: '10px 0' }}>
                  <DegreeDropdown />
                </span>
              )}

              {!isSimplifiedPageHeader && (
                <React.Fragment>
                  <span className={childItemClassName} role="none">
                    {/* For the test, emulate EnterpriseLink visibility */}
                    <CareerAcademyLink shouldShow={shouldShowOptionalElements} />
                  </span>
                  <span className={childItemClassName} role="none">
                    <EnterpriseLink
                      hideEnterprise={hideEnterprise}
                      showAdminLinks={showAdminLinks}
                      showExploreCatalog={showExploreCatalog}
                    />
                  </span>
                  <span className={childItemClassName} role="none">
                    <StudentLink
                      hideEnterprise={hideEnterprise}
                      showAdminLinks={showAdminLinks}
                      showExploreCatalog={showExploreCatalog}
                      isEnterprise={isEnterprise}
                    />
                  </span>
                </React.Fragment>
              )}

              {showAuthenticatedAccountInfo ? (
                <AuthenticatedAccountInfo
                  className={childListClassName}
                  adminPermission={adminPermission}
                  isStaff={user.get().is_staff || adminPermission?.canViewAdminLink()}
                  userId={user.get().id}
                  showShoppingCart={!!showShoppingCart}
                  hideNotificationCenter={!!hideNotificationCenter}
                  programs={programs}
                  degrees={degrees}
                  hideEnterprise={hideEnterprise}
                  showAdminLinks={showAdminLinks}
                  thirdPartyOrganizationId={thirdPartyOrganizationId || ''}
                  course={course}
                  showLanguagesDropdown={showLanguagesDropdown}
                  itemId={itemId}
                  showUnifiedLinks={showUnifiedLinks}
                  hideDropdownOptions={hideDropdownOptions}
                />
              ) : (
                <UnauthenticatedAccountInfo
                  className={childListClassName}
                  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                  id="unauthenticated-info-menu"
                  isStandaloneAuth={isStandaloneAuth}
                  hideEnterprise={hideEnterprise}
                  q2ShowDesktopAltSignupLabel={q2ShowDesktopAltSignupLabel}
                  isEnterprise={isEnterprise}
                  experimentalStyles={experimentalStyles}
                  hideLogIn={hideLogIn}
                  showUnifiedLinks={showUnifiedLinks}
                  showLanguagesDropdown={showLanguagesDropdown}
                  thirdPartyOrganizationId={thirdPartyOrganizationId}
                  course={course}
                />
              )}
            </div>
          );
        }}
      </PageHeaderContext.Consumer>
    );
  }
}
export default compose<Props, InputProps>(
  branch(
    () => user.isAuthenticatedUser() && typeof window !== 'undefined',
    compose(
      Naptime.createContainer<Props, Omit<Props, 'adminUserPermissions'>>(() => {
        return {
          adminUserPermissions: AdminUserPermissionsV1.finder('my', { params: {}, required: false }),
        };
      }),
      mapProps((props: Props & { adminUserPermissions: Array<AdminUserPermissionsV1> }) => {
        const { adminUserPermissions = [] } = props;
        const permission = (adminUserPermissions || [])[0]?.permissions;
        const adminPermission = new AdminPermission(permission || {});

        const cartInfo = savedCartUtils.get();
        const cartId = cartInfo && cartInfo.id;

        return {
          ...props,
          adminPermission,
          hasActiveShoppingCart: !!cartId,
        };
      })
    )
  )
)(HeaderRightNav);
