import type { Dispatcher } from 'vendor/cnpm/fluxible.v0-4/addons/BaseStore';
import BaseStore from 'vendor/cnpm/fluxible.v0-4/addons/BaseStore';

/**
 * @deprecated
 * The purpose of this store is to persist the `resultsState`, which is retrieved during SSR of
 * the Algolia search results page.
 * The persisted `resultsState` is then retrieved when instantiating
 * AlgoliaInstantSearchParentComponent.
 */
class AlgoliaResultsStateStore extends BaseStore {
  static storeName = 'AlgoliaResultsStateStore';

  resultsState: unknown;

  emitChange!: () => void;

  static handlers = {
    RECEIVE_RESULTS_STATE: 'onReceiveResultsState',
  };

  constructor(dispatcher: Dispatcher) {
    super(dispatcher);
    this.resultsState = null;
  }

  dehydrate() {
    return {
      resultsState: this.resultsState,
    };
  }

  rehydrate(state: { resultsState: unknown }) {
    this.resultsState = state.resultsState;
  }

  hasLoaded() {
    return !!this.resultsState;
  }

  onReceiveResultsState(data: unknown) {
    this.resultsState = data;
    this.emitChange();
  }
}

export default AlgoliaResultsStateStore;
