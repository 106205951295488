/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { Button, Grid, Typography, breakpoints, useMediaQuery } from '@coursera/cds-core';
import type { GridProps } from '@coursera/cds-core';

import {
  DEFAULT_GRID_BREAKPOINTS_CONFIG,
  PAGE_HEADER_HEIGHT,
} from 'bundles/cds-labs/components/ShowMoreGridSection/constants';

import _t from 'i18n!nls/collections-carousel';

type GridSize = NonNullable<Exclude<GridProps['lg'], boolean>>;
type GridSpacing = GridProps['spacing'];

export const styles = {
  container: css`
    padding: var(--cds-spacing-400) 0;
  `,

  titleSection: css`
    margin-bottom: var(--cds-spacing-150);
  `,

  itemList: css`
    list-style: none;
    padding: 0;
  `,
  buttonsWrapper: css`
    gap: var(--cds-spacing-200);
    margin-top: var(--cds-spacing-150);
    align-items: center;
    ${breakpoints.up('sm')} {
      display: flex;
    }
  `,
};

// define the requirement of a basic item props
export type BaseItem = { id: string };
export type renderItemProps<Item extends BaseItem> = {
  item: Item;
  itemIndex: number;
  itemRef?: React.RefObject<HTMLAnchorElement>;
  isExpandedCollection?: boolean;
  defaultCardsCount?: number;
};
type NumberGridSize = Exclude<GridSize, 'auto'>;
export type GridConfig = { lg?: NumberGridSize; md: NumberGridSize; sm: NumberGridSize; xs: NumberGridSize };
export type ExpandButtonProps = {
  onClick: () => void;
  onKeyDown: () => void;
  showMoreCount: number;
  isExpanded: boolean;
  defaultLabel: string;
};

export type ShowMoreGridSectionProps<Item extends BaseItem> = {
  className?: string;

  /**
   * Number of default showed row before expanding.
   * @default 1
   */
  defaultRows?: number;

  /**
   * Max number of items to show for every progressive expansion
   * @default 8
   */
  showMoreAmount?: number;

  /**
   * Defines the space between the item components.
   */
  spacing?: GridSpacing;

  /**
   * Array of items to show in the collection. Item is type parameter defined when using.
   */
  items: Item[];

  /**
   * Optional prop to update items Grid Config for responsiveness.
   * @default DEFAULT_GRID_BREAKPOINTS_CONFIG
   */
  itemsGridConfig?: GridConfig;

  /**
   * Function that will be called every time the collection expands and completely expanded
   * @param isExpanded whether the collection is expanded
   * @param isCompletelyExpanded whether the collection is fully expanded i.e. all items are visible
   */
  onExpanded?: ({ isExpanded, isCompletelyExpanded }: { isExpanded: boolean; isCompletelyExpanded: boolean }) => void;

  /**
   * Function that return a rendered title.
   */
  renderTitle?: () => React.ReactNode;

  /**
   * Function that return a rendered sub section.
   */
  renderSubSection?: () => React.ReactNode;

  /**
   * Function that return a rendered footer.
   */
  renderFooter?: () => React.ReactNode;

  /**
   * Function that return a rendered expand button.
   */
  renderExpandButton?: ({ onClick, showMoreCount, isExpanded, defaultLabel }: ExpandButtonProps) => React.ReactNode;

  /**
   * Function that return a rendered secondary CTA.
   */
  renderSecondaryCta?: () => React.ReactNode;

  /**
   * Function that return a rendered item.
   */
  renderItem: ({ item, itemIndex }: renderItemProps<Item>) => React.ReactNode;

  /**
   * Title to display.
   */
  title?: string;
};

function ExpandButton({ onClick, defaultLabel, onKeyDown }: ExpandButtonProps) {
  return (
    <Button size="small" variant="secondary" onKeyDown={onKeyDown} onClick={onClick}>
      {defaultLabel}
    </Button>
  );
}

/**
 * ShowMoreGridSection provides a way to show more collection of items that is in Grid (responsive) format
 * with the addition of title, sub section, and footer.
 *
 *
 * @example
 * <ShowMoreGridSection
 *  title="ShowMoreGridSection with custom footer"
 *  items={items.slice(0, 4)}
 *  itemsGridConfig={{ xs: 6, sm: 3, md: 2 }}
 *  renderItem={renderItem}
 *  renderTitle={() => <a href="https://www.coursera.org">ShowMoreGridSection with custom title (link)</a>}
 *  renderSubSection={() => <Typography variant="body2" color="success">
 *      custom sub section component
 *    </Typography>
 *  }
 *  renderExpandButton={() => <a href="https://www.coursera.org">link to page</a>}
 *  renderFooter={() => <Typography variant="body2" color="success">
 *      custom footer component
 *    </Typography>
 *  }
 * />
 */
export function ShowMoreGridSection<Item extends BaseItem>({
  renderTitle,
  renderSubSection,
  renderFooter,
  renderExpandButton,
  renderSecondaryCta,
  onExpanded,
  items,
  renderItem,
  itemsGridConfig = DEFAULT_GRID_BREAKPOINTS_CONFIG,
  defaultRows = 1,
  title,
  showMoreAmount = 8,
  spacing,
  ...props
}: ShowMoreGridSectionProps<Item>) {
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const isTablet = useMediaQuery(breakpoints.down('sm'));
  const isWideScreen = useMediaQuery(breakpoints.up('lg'));
  const [isExpanded, setIsExpanded] = useState(false);
  const hasCalledOnExpanded = useRef(false);
  const sectionRef = useRef<HTMLElement>(null);
  // keep track of a keyboard interaction with the CTA
  // so we can manually manage focus, see useEffect()
  const isKeyboardEvent = useRef<boolean>(false);
  const firstExpandedRef = React.createRef<HTMLAnchorElement>();
  let cardsPerRow = 12 / itemsGridConfig.md;
  if (isTablet) cardsPerRow = 12 / itemsGridConfig.sm;
  if (isMobile) cardsPerRow = 12 / itemsGridConfig.xs;
  if (isWideScreen && itemsGridConfig.lg) cardsPerRow = 12 / itemsGridConfig.lg;
  const defaultCardsCount = cardsPerRow * defaultRows;
  const [numItemsToDisplay, setNumItemsToDisplay] = useState(defaultCardsCount);
  const [itemIndexToFocus, setitemIndexToFocus] = useState(defaultCardsCount);
  const itemsToShow = items.slice(0, numItemsToDisplay);
  const itemsLeft = items.length - itemsToShow.length;
  const showMoreCount = Math.min(showMoreAmount, itemsLeft);
  const isCompletelyExpanded = itemsLeft === 0;
  const showExpandButton = items.length > defaultCardsCount;
  const expandButtonLabel = isCompletelyExpanded
    ? _t('Show fewer')
    : _t('Show #{showMoreCount} more', { showMoreCount });
  let renderedTitle;

  useEffect(() => {
    if (isExpanded && isKeyboardEvent.current && firstExpandedRef?.current) {
      // manually set focus on the first list item after expand to
      // preserve keyboard nav order
      firstExpandedRef?.current?.focus?.();
      isKeyboardEvent.current = false;
      if (isCompletelyExpanded) {
        setitemIndexToFocus(defaultCardsCount);
      } else {
        setitemIndexToFocus(numItemsToDisplay);
      }
    }
    if (numItemsToDisplay !== defaultCardsCount && !isExpanded) {
      setNumItemsToDisplay(defaultCardsCount);
    }
  }, [isExpanded, firstExpandedRef, numItemsToDisplay, isKeyboardEvent, defaultCardsCount, isCompletelyExpanded]);
  useEffect(() => {
    // reset expanded state if items changed
    setIsExpanded(false);
    setNumItemsToDisplay(defaultCardsCount);
  }, [items, defaultCardsCount]);
  useEffect(() => {
    if (isCompletelyExpanded && !hasCalledOnExpanded.current) {
      onExpanded?.({ isExpanded, isCompletelyExpanded });
      hasCalledOnExpanded.current = true;
    } else if (!isCompletelyExpanded) {
      onExpanded?.({ isExpanded, isCompletelyExpanded });
      hasCalledOnExpanded.current = false;
    }
  }, [onExpanded, isExpanded, isCompletelyExpanded, hasCalledOnExpanded]);

  if (!items || items.length === 0) {
    return null;
  }

  if (renderTitle) {
    renderedTitle = renderTitle();
  } else if (title) {
    renderedTitle = (
      <Typography variant="h1" component="h2">
        {title}
      </Typography>
    );
  }

  const buttonOnClick = () => {
    if (!isExpanded) setIsExpanded(true);
    if (isCompletelyExpanded) {
      // scroll back to the top of the collection
      const top = (sectionRef.current?.getBoundingClientRect().y || 0) - PAGE_HEADER_HEIGHT;
      window.scrollBy({ top, behavior: 'smooth' });
      setNumItemsToDisplay(defaultCardsCount);
      setIsExpanded(false);
    } else {
      setNumItemsToDisplay(numItemsToDisplay + showMoreCount);
    }
  };

  const expandButtonProps = {
    onClick: buttonOnClick,
    showMoreCount,
    isExpanded,
    defaultLabel: expandButtonLabel,
    onKeyDown: () => {
      isKeyboardEvent.current = true;
    },
  };

  const secondaryCta = renderSecondaryCta ? renderSecondaryCta() : null;

  return (
    <section css={styles.container} ref={sectionRef} {...props}>
      {(renderedTitle || renderSubSection) && (
        <div css={styles.titleSection}>
          {renderedTitle}
          {renderSubSection?.()}
        </div>
      )}
      <Grid container component="ul" spacing={spacing} css={styles.itemList}>
        {itemsToShow.map((item, itemIndex) => (
          <Grid key={item.id} item {...itemsGridConfig} component="li">
            {renderItem({
              item,
              itemIndex,
              itemRef: itemIndex === itemIndexToFocus ? firstExpandedRef : undefined,
              isExpandedCollection: isExpanded,
              defaultCardsCount,
            })}
          </Grid>
        ))}
      </Grid>
      {(showExpandButton || secondaryCta) && (
        <div className="ShowMoreGridSection-button-wrapper" css={styles.buttonsWrapper}>
          {renderExpandButton && showExpandButton ? renderExpandButton(expandButtonProps) : null}
          {!renderExpandButton && showExpandButton ? <ExpandButton {...expandButtonProps} /> : null}
          {secondaryCta}
        </div>
      )}
      {renderFooter?.()}
    </section>
  );
}

export default ShowMoreGridSection;
