import type { PartnerListModulePartner } from 'bundles/unified-common/modules/PartnerListModule';

import dukeUpdated2020 from 'images/coursera-plus/duke_updated_2020.png';
import google from 'images/coursera-plus/google.png';
import illinois from 'images/coursera-plus/illinois.svg';
import jhuLogo from 'images/coursera-plus/jhu_logo.png';
import meta from 'images/coursera-plus/meta.png';
import duke from 'images/coursera-plus/partnerLogos/1-duke.png';
import illinois2 from 'images/coursera-plus/partnerLogos/2-illinois.png';
import google2 from 'images/coursera-plus/partnerLogos/3-google.png';
import umich2 from 'images/coursera-plus/partnerLogos/4-umich.png';
import ibm2 from 'images/coursera-plus/partnerLogos/5-ibm.png';
import vanderbilt2 from 'images/coursera-plus/partnerLogos/6-vanderbilt.png';
import johnshopkins from 'images/coursera-plus/partnerLogos/7-johnshopkins.png';
import iima from 'images/coursera-plus/partnerLogos/iima.png';
import isb from 'images/coursera-plus/partnerLogos/isb-wide.png';
import michigan from 'images/coursera-plus/partnerLogos/logo-michigan.png';
import yaleLogo from 'images/coursera-plus/partnerLogos/yale-logo.png';
import umich from 'images/coursera-plus/umich.png';
import vanderbilt from 'images/coursera-plus/vanderbilt-logo.png';
import microsoft from 'images/microsoft-logo.png';
import penn from 'images/penn-university-logo.png';
import ibm from 'images/promotions/ibm_logo.png';
import washington from 'images/universities/logo-uw.png';

import _t from 'i18n!nls/coursera-plus';

export const MONTHLY_SUBSCRIPTION_CTA = 'monthly_subscription_cta';
export const FOOTER_CTA = 'footer_cta';

export type PartnerLogo = {
  id: string;
  title: string;
  image: string;
  height: number;
};

export type ValueProp = {
  title: string;
  subtitle: string;
  image: string;
  height: number;
};

export const getPartnerLogos = (): Array<PartnerLogo> => [
  {
    id: 'illinois',
    title: _t('University of Illinois at Urbana-Champaign'),
    image: `${illinois}`,
    height: 32,
  },
  {
    id: 'duke',
    title: _t('Duke University'),
    image: `${dukeUpdated2020}`,
    height: 37,
  },
  {
    id: 'google',
    title: _t('Google'),
    image: `${google}`,
    height: 37,
  },
  {
    id: 'umich',
    title: _t('University of Michigan'),
    image: `${umich}`,
    height: 55,
  },
  {
    id: 'ibm',
    title: _t('IBM'),
    image: `${ibm}`,
    height: 32,
  },
  {
    id: 'vanderbilt',
    title: _t('Vanderbilt'),
    image: `${vanderbilt}`,
    height: 32,
  },
  {
    id: 'jhu',
    title: _t('Johns Hopkins University'),
    image: `${jhuLogo}`,
    height: 37,
  },
];

export const getPartnerLogosForCSCollectionLandingPage = (): Array<PartnerLogo> => [
  {
    id: 'meta',
    title: _t('Meta'),
    image: `${meta}`,
    height: 60,
  },
  {
    id: 'microsoft',
    title: _t('Microsoft'),
    image: `${microsoft}`,
    height: 35,
  },
  {
    id: 'university-of-pennsylvania',
    title: _t('University of Pennsylvania'),
    image: `${penn}`,
    height: 37,
  },
  {
    id: 'duke',
    title: _t('Duke University'),
    image: `${dukeUpdated2020}`,
    height: 32,
  },
];

export const getPartnerLogosForGenerativeAILandingPage = (): Array<PartnerLogo> => [
  {
    id: 'university-of-pennsylvania',
    title: _t('University of Pennsylvania'),
    image: `${penn}`,
    height: 41,
  },
  {
    id: 'duke',
    title: _t('Duke University'),
    image: `${dukeUpdated2020}`,
    height: 37,
  },
  {
    id: 'google',
    title: _t('Google'),
    image: `${google}`,
    height: 37,
  },
  {
    id: 'ibm',
    title: _t('IBM'),
    image: `${ibm}`,
    height: 37,
  },
  {
    id: 'vanderbilt',
    title: _t('Vanderbilt'),
    image: `${vanderbilt}`,
    height: 32,
  },
  {
    id: 'microsoft',
    title: _t('Microsoft'),
    image: `${microsoft}`,
    height: 35,
  },
  {
    id: 'washington',
    title: _t('Washington University'),
    image: `${washington}`,
    height: 47,
  },
];

export const getPartnerLogosForCPlusEnrollThroughLIHP = (): Array<PartnerLogo> => [
  {
    id: 'google',
    title: _t('Google'),
    image: `${google}`,
    height: 24,
  },
  {
    id: 'yaleLogo',
    title: _t('Yale University'),
    image: `${yaleLogo}`,
    height: 22,
  },
  {
    id: 'microsoft',
    title: _t('Microsoft'),
    image: `${microsoft}`,
    height: 22,
  },
  {
    id: 'duke',
    title: _t('Duke University'),
    image: `${dukeUpdated2020}`,
    height: 22,
  },
  {
    id: 'michigan',
    title: _t('University of Michigan'),
    image: `${michigan}`,
    height: 22,
  },
];

export const getPartnerLogosForCPlusIndiaPromoMid2024PromoPage = (): Array<PartnerLogo> => [
  {
    id: 'isb',
    title: _t('Indian School of Business'),
    image: `${isb}`,
    height: 30,
  },
  {
    id: 'google',
    title: _t('Google'),
    image: `${google}`,
    height: 24,
  },
  {
    id: 'ibm',
    title: _t('IBM'),
    image: `${ibm}`,
    height: 32,
  },
  {
    id: 'umich',
    title: _t('University of Michigan'),
    image: `${umich}`,
    height: 55,
  },
  {
    id: 'meta',
    title: _t('Meta'),
    image: `${meta}`,
    height: 60,
  },
  {
    id: 'microsoft',
    title: _t('Microsoft'),
    image: `${microsoft}`,
    height: 22,
  },
  {
    id: 'iima',
    title: _t('IIMA - IIM Ahmedabad'),
    image: `${iima}`,
    height: 38,
  },
];

export const getRedesignPartnerLogos = (): PartnerListModulePartner[] => [
  {
    id: 'duke',
    logo: {
      url: duke,
    },
  },
  {
    id: 'illinois',
    logo: { url: illinois2 },
  },
  {
    id: 'google',
    logo: { url: google2 },
  },
  {
    id: 'umich',
    logo: { url: umich2 },
  },
  {
    id: 'ibm',
    logo: { url: ibm2 },
  },
  {
    id: 'vanderbilt',
    logo: { url: vanderbilt2 },
  },
  {
    id: 'johnshopkins',
    logo: { url: johnshopkins },
  },
];

export const getPartnerLogosForOnboardingCplusUpsell = (): Array<PartnerLogo> => [
  {
    id: 'google',
    title: _t('Google'),
    image: `${google}`,
    height: 20,
  },
  {
    id: 'yaleLogo',
    title: _t('Yale University'),
    image: `${yaleLogo}`,
    height: 16,
  },
  {
    id: 'microsoft',
    title: _t('Microsoft'),
    image: `${microsoft}`,
    height: 12,
  },
  {
    id: 'umich',
    title: _t('University of Michigan'),
    image: `${michigan}`,
    height: 16,
  },
  {
    id: 'meta',
    title: _t('Meta'),
    image: `${meta}`,
    height: 30,
  },
  {
    id: 'university-of-pennsylvania',
    title: _t('University of Pennsylvania'),
    image: `${penn}`,
    height: 18,
  },
  {
    id: 'ibm',
    title: _t('IBM'),
    image: `${ibm}`,
    height: 14,
  },
  {
    id: 'duke',
    title: _t('Duke University'),
    image: `${dukeUpdated2020}`,
    height: 14,
  },
];

export const DEFAULT_COLLECTION_SLUG = 'coursera-plus-landing-page-april-2024';

export const ENROLL_BUTTON_TESTID = 'coursera-plus-enroll-button';
