import API from 'js/lib/api';

const emailVerificationUtils = {
  verifyEmailToken(token: string): JQueryXHR {
    const api = API('/api/grpc/useremails/v1/UserEmailsAPI', {
      type: 'rest',
    });
    const data = { token };
    return api.post('VerifyUserEmail', { data });
  },
};

/* Commonly misspelled domains taken from this audit: https://docs.google.com/spreadsheets/d/1-DRxSFAAArN5-5Le6xVPcoi3drXU55VjU4d6HYY0IiY/edit#gid=549105264 */
// see also: https://coursera.atlassian.net/wiki/spaces/GROW/pages/1607762372/Email+Domain+Validation+on+Signup
export const isMisspelledDomain = (
  domain: string,
  { checkOnlyGmail }: { checkOnlyGmail: boolean } = { checkOnlyGmail: false }
): boolean => {
  const validPrefixes = checkOnlyGmail ? ['gmail'] : ['gmail', 'yahoo', 'hotmail'];

  // These are definitely valid, short circuit and return right away
  if (validPrefixes.map((prefix) => `${prefix}.com`).includes(domain)) return false;

  for (const prefix of validPrefixes) {
    const withExtraCharacters = new RegExp(prefix + '.com(.+)', 'g');
    if (withExtraCharacters.test(domain)) return true;
  }

  const [prefix] = domain.split('.', 1);
  // We don't want to let through any gmail address with a weird suffix
  if (prefix === 'gmail') return true;

  const MISTYPED_GMAIL = [
    'gail.com',
    'gamail.com',
    'gamil.com',
    'gemail.com',
    'gmai.com',
    'gmail.com',
    'gmaill.com',
    'gmial.com',
    'gmsil.com',
    'gnail.com',
  ];

  const MISTYPED_HOTMAIL = ['htmail.com'];

  const MISTYPED_YAHOO = ['yahoo.con', 'yahoo.co'];

  const MISTYPED_ALL = checkOnlyGmail ? MISTYPED_GMAIL : [...MISTYPED_GMAIL, ...MISTYPED_HOTMAIL, ...MISTYPED_YAHOO];

  return MISTYPED_ALL.includes(domain);
};

export const { verifyEmailToken } = emailVerificationUtils;

export default emailVerificationUtils;
