/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Button, breakpoints } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import type { PropsForEnrollPresentation } from 'bundles/coursera-plus/components/CourseraPlusEnrollButton';
import { ENROLL_BUTTON_TESTID } from 'bundles/coursera-plus/components/constants';
import ApiButton from 'bundles/red-shibas-cds/components/ApiButton';

const TrackedCdsButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);
const TrackedEnrollButton = withSingleTracked({ type: 'BUTTON' })(ApiButton);

const API_IN_PROGRESS = 'API_IN_PROGRESS';

const emotionStyles = {
  link: css`
    max-width: 420px;
    padding-top: 0;
  `,
  singleBigButton: {
    [`@media (max-width: ${breakpoints.values.sm - 1}px)`]: {
      width: '100%',
    },
  },
  singleBigButtonFullWidthMdAndDown: {
    [`@media (max-width: ${breakpoints.values.md - 1}px)`]: {
      width: '100%',
      maxWidth: 'unset',
    },
  },
  singleBigButtonFullWidthSmAndDown: {
    [`@media (max-width: ${breakpoints.values.sm - 1}px)`]: {
      width: '100%',
      maxWidth: 'unset',
    },
  },
  singleBigButtonSecondary: {
    [`@media (max-width: ${breakpoints.values.sm - 1}px)`]: {
      width: '100%',
      maxWidth: 'unset',
    },
  },
  linkWhite: {
    marginTop: '5px',
    fontSize: '16px',
    color: 'white!important',
    fontWeight: 'normal',
    cursor: 'pointer',
    backgroundColor: 'transparent !important',
    borderColor: 'transparent !important',
    ':hover': {
      color: '#eeeeee !important',
    },
  },
};

export const BigButtonNoExpand: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  return (
    <TrackedEnrollButton
      trackingName={trackingName}
      onClick={onClick}
      variant="primary"
      size="medium"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e={ENROLL_BUTTON_TESTID}
      disabled={apiStatus === API_IN_PROGRESS}
    />
  );
};

export const BigButton: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  return (
    <TrackedEnrollButton
      css={emotionStyles.singleBigButton}
      trackingName={trackingName}
      onClick={onClick}
      variant="primary"
      size="medium"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e={ENROLL_BUTTON_TESTID}
      disabled={apiStatus === API_IN_PROGRESS}
    />
  );
};

export const BigButtonFullWidthMdAndDown: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  return (
    <TrackedEnrollButton
      css={emotionStyles.singleBigButtonFullWidthMdAndDown}
      trackingName={trackingName}
      onClick={onClick}
      variant="primary"
      size="medium"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e={ENROLL_BUTTON_TESTID}
      disabled={apiStatus === API_IN_PROGRESS}
    />
  );
};

export const BigButtonFullWidthSmAndDown: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  return (
    <TrackedEnrollButton
      css={emotionStyles.singleBigButtonFullWidthSmAndDown}
      trackingName={trackingName}
      onClick={onClick}
      variant="primary"
      size="medium"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e={ENROLL_BUTTON_TESTID}
      disabled={apiStatus === API_IN_PROGRESS}
    />
  );
};

export const BigButtonSecondary: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  return (
    <TrackedEnrollButton
      css={emotionStyles.singleBigButtonSecondary}
      trackingName={trackingName}
      onClick={onClick}
      variant="secondary"
      size="medium"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e={ENROLL_BUTTON_TESTID}
      disabled={apiStatus === API_IN_PROGRESS}
    />
  );
};

export const SmallButton: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  return (
    <TrackedEnrollButton
      trackingName={trackingName}
      onClick={onClick}
      variant="primary"
      size="small"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e={ENROLL_BUTTON_TESTID}
      disabled={apiStatus === API_IN_PROGRESS}
    />
  );
};

export const Link: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;

  return (
    <TrackedCdsButton
      disabled={apiStatus === API_IN_PROGRESS}
      loading={apiStatus === API_IN_PROGRESS}
      trackingName={trackingName}
      variant="ghost"
      edgeAlign="start"
      onClick={onClick}
      css={emotionStyles.link}
    >
      {props.label}
    </TrackedCdsButton>
  );
};

export const LinkOnDark: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;

  return (
    <TrackedCdsButton
      disabled={apiStatus === API_IN_PROGRESS}
      loading={apiStatus === API_IN_PROGRESS}
      trackingName={trackingName}
      variant="ghostInvert"
      edgeAlign="start"
      onClick={onClick}
      css={emotionStyles.link}
    >
      {props.label}
    </TrackedCdsButton>
  );
};
